window.addEventListener('DOMContentLoaded', function () {
  const videoPopupBtn = document.querySelectorAll('.video-popup__btn');

  videoPopupBtn.forEach((videoBtn) => {
    videoBtn.addEventListener('click', function () {
      const videoPopupParent = videoBtn.closest('.video-popup-block');
      const videoPopup = videoPopupParent.querySelector('.video-embed-popup');
      videoPopup.classList.toggle('popup--active');
    });
  });
});
